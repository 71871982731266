.printContainer{
  display: 'flex';
  flex-direction: 'column';
  align-items: center;
  align-self: center;
  height: fit-content;  
  /* background-image: url('./WhatsApp\ Image\ 2025-01-20\ at\ 23.31.01_efada741.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
}
.print {
  transform: rotate(270deg) translateX(-5.5cm);
  width: 21cm;
  height: 8cm;
  align-self: center;
  page-break-before: always;
  page-break-after: always;
  padding: 0 !important;
  transform: translate(10px,10px);
}
.print span {
  font-size: 10px;
  color: black;
  position: absolute;
  line-height: 12px;
}

.departure {
  bottom: 1.7cm;
  left: 9cm;
}

.date {
  bottom: 1.7cm;
  left: 5cm;
}

.seatNumber {
  bottom: 1.7cm;
  left: 12.2cm;
}

.tripNumber {
  top: 1cm;
  left: 5.25cm;
}

.tripSerial {
  top: 2cm;
  left: 7cm;
}

.price {
  bottom: 3cm;
  right: 2.9cm;
}
.priceTop {
  top: 3.2cm;
  left: 13.2cm;
}

.fromLocation {
  bottom: 3cm;
  left: 4.6cm;
  text-align: center;
}
.ticketCodeTop {
  top: 1cm;
  left: 6.8cm;
}
.toLocation {
  bottom: 3cm;
  left: 12.1cm;
  text-align: center;
}

.issueDateTop {
  top: 2.1cm;
  left: 13cm;
}
.issueDateBottom {
  bottom: 1.7cm;
  right: 1.9cm;
}
.discountPrice {
  bottom: .8cm;
  right: 3.7cm;
  color: red;
}
.tripId{
  top: 3.5cm;
  right: 2.5cm;

}
.qrCode {
  position: absolute;
  top: 0cm;
  right: 1.5cm;
}

